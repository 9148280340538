import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "static-container" }
const _hoisted_2 = { class: "static-card my-40" }
const _hoisted_3 = { class: "flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_link = _resolveComponent("c-link")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.tabList, (link) => {
          return (_openBlock(), _createBlock(_component_c_link, {
            key: link.name,
            class: "mr-24",
            type: link.type,
            name: link.name,
            to: link.to
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(link.label), 1)
            ]),
            _: 2
          }, 1032, ["type", "name", "to"]))
        }), 128))
      ]),
      _createVNode("div", null, [
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}