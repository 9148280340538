
import { defineComponent } from 'vue';
import { CNavbarLink } from '@/models/CNavbarLink.model';
import CLink from '@/components/shared/CLink.vue';
import { InfoTabPath } from '@/enums/InfoTabPath.enum';
import { InfoTabName } from '@/enums/InfoTabName.enum';
import { LinkType } from '@/enums/LinkType.enum';
import { t } from '@/utils/utils';
import { useMeta } from 'vue-meta';

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'Info',
	components: { CLink },
	setup() {
		useMeta({
			title: t('nav.info'),
		})
	},
	computed: {
		tabList(): CNavbarLink[] {
			return [
				new CNavbarLink({
					to: InfoTabPath.Q_AND_A,
					name: InfoTabName.Q_AND_A,
					type: LinkType.TAB,
					label: this.$t('tab.info.qAndA'),
				}),
				new CNavbarLink({
					to: InfoTabPath.CLIMATE_INDEXES,
					name: InfoTabName.CLIMATE_INDEXES,
					type: LinkType.TAB,
					label: this.$t('tab.info.climateIndexes'),
				}),
				new CNavbarLink({
					to: InfoTabPath.FILE_NAMES,
					name: InfoTabName.FILE_NAMES,
					type: LinkType.TAB,
					label: this.$t('tab.info.fileNames'),
				}),
				new CNavbarLink({
					to: InfoTabPath.CITING_DATA,
					name: InfoTabName.CITING_DATA,
					type: LinkType.TAB,
					label: this.$t('tab.info.citingData'),
				}),
			];
		},
	},
});
